<!-- adpat to vuetify dropdown -->
<template>
  <v-menu offset-y :value="openMenu" @input="$emit('toggleLanguage')">
    <template v-slot:activator="{ on, attrs }">
      <img
        style="width: 28px; height: 20px"
        class="d-block mt-2 mb-1 mr-1"
        :src="getIcon()"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-list>
      <v-list-item
        v-for="(lang, index) in langs"
        :key="index"
        class="lang-menu-item"
        style="cursor: pointer"
      >
        <div class="d-flex align-center" @click="setLang(lang.value)">
          <img style="width: 32px" :src="lang.iconSrc" class="mr-2 d-block" />
          <span>{{ lang.text }}</span>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["menu"],
  watch: {
    menu: function () {
      this.openMenu = this.menu;
    },
  },
  data() {
    return {
      openMenu: false,
      selectedLang: this.$i18n.locale,
      langs: [
        {
          value: "es",
          text: "Español",
          iconSrc: require("@/assets/images/flags/es.png"),
        },
        {
          value: "en",
          text: "English",
          iconSrc: require("@/assets/images/flags/en.png"),
        },
        {
          value: "pt",
          text: "português",
          iconSrc: require("@/assets/images/flags/pt.png"),
        },
        {
          value: "ca",
          text: "Català",
          iconSrc: require("@/assets/images/flags/ca.png"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ language: "language/language" }),
    ...mapGetters({ isLogged: "user/isLogged" }),
    getIcon() {
      return (item) => {
        let image = item || this.selectedLang;
        let iconSrc = null;
        Object.keys(this.langs).forEach((index) => {
          if (this.langs[index].value === image) {
            iconSrc = this.langs[index].iconSrc;
          }
        });
        return iconSrc;
      };
    },
  },

  methods: {
    ...mapActions({ setLanguage: "language/setLanguage" }),
    ...mapActions({
      updateUserPreferencesLanguage: "user/updateUserPreferencesLanguage",
    }),
    setLang(lang) {
      this.selectedLang = lang;
      this.$i18n.locale = lang;
      this.setLanguage(lang);
      if (this.isLogged) this.updateUserPreferencesLanguage(lang);
    },
  },
};
</script>
