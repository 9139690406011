<template>
  <!-- Help menu -->
  <v-menu offset-y bottom :value="openMenu" @input="$emit('toggleLanguage')">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-title class="font-weight-light secondary--text">
          <v-icon color="primary">{{ "mdi-help-circle-outline" }}</v-icon>

          {{ $t("contact.title") }}
        </v-list-item-title>
      </v-list-item></template
    >
    <v-list class="rounded-lg">
      <v-row dense v-for="(option, index) in helpOptions" :key="index">
        <v-list-item :to="{ name: option.router }">
          <v-list-item-title class="font-weight-light secondary--text">
            <v-icon color="primary">{{ option.icon }}</v-icon>

            {{ $tc(option.title, 2) }}
          </v-list-item-title>
        </v-list-item>
      </v-row>
    </v-list>
  </v-menu>
</template>

<script>
import helpOptions from "@/components/layouts/helpOptions.js";
export default {
  props: ["menu"],
  watch: {
    menu: function() {
      this.openMenu = this.menu;
    }
  },
  data() {
    return {
      openMenu: false,
      helpOptions: helpOptions
    };
  }
};
</script>
