export default [
	{
		title: "",
		icon: "",
		key: "help",
		router: "",
		permissions: [ "candidate", "pharmacy", "alquimia", "expert", "pharmacygroup" ]
	},
	{
		title: "notification.title",
		icon: "mdi-bell",
		key: "notifications",
		router: "notifications",
		permissions: [ "candidate", "pharmacy", "expert", "pharmacygroup" ]
	},
	{
		title: "profile.title",
		icon: "mdi-account",
		key: "profile",
		router: "profile",
		permissions: [ "candidate", "pharmacy", "pharmacygroup" ]
	},
	{
		title: "transaction.title",
		icon: "mdi-credit-card",
		key: "transactions",
		router: "transactions",
		permissions: [ "pharmacy", "pharmacygroup" ]
	},
	// {
	//   title: "configuration.title",
	//   icon: "mdi-cog",
	//   key: "configuration",
	//   router: "configuration"
	// },
	{
		title: "configuration.language",
		icon: "",
		key: "language",
		router: "",
		permissions: [ "candidate", "pharmacy", "alquimia", "expert", "pharmacygroup" ]
	},
	{
		title: "configuration.reset_password",
		icon: "mdi-key",
		key: "reset_password",
		router: "reset-password",
		permissions: [ "candidate", "pharmacy", "alquimia", "expert", "pharmacygroup" ]
	},
	{
		title: "logout",
		icon: "mdi-logout",
		key: "logout",
		router: "",
		permissions: [ "candidate", "pharmacy", "alquimia", "expert", "pharmacygroup" ]
	}
];
