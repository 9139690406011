export default [
  {
    title: "terms.title",
    icon: "mdi-bank",
    router: "terms-and-conditions"
  },
  {
    title: "privacy.title",
    icon: "mdi-lock-outline",
    router: "privacy-policy"
  }
];
