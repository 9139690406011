var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"backgroundLayout",attrs:{"app":"","flat":""}},[_c('v-container',[_c('v-row',{attrs:{"justify-sm":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","md":"6","lg":"6","sm":"12"}},[_c('v-toolbar-items',[(_vm.back)?_c('v-icon',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("mdi-arrow-left")]):_vm._e(),_c('v-spacer'),(_vm.isLogged)?_c('v-menu',{staticClass:"rounded-lg",attrs:{"offset-y":"","nudge-left":70,"nudge-bottom":5},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"shrink ml-4",attrs:{"alt":"ApoTalent Logo","contain":"","src":require("@/assets/apotalent/Isotipo white.png"),"transition":"scale-transition","width":"40"}},'v-img',attrs,false),on))]}}],null,false,2528044822)},[_c('v-list',_vm._l((_vm.legalOptions),function(option,index){return _c('v-list-item',{key:index,staticClass:"legal-options",attrs:{"to":{ name: option.router }},on:{"click":function () {}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"}},[_vm._v(_vm._s(option.icon))]),_c('v-list-item-title',{staticClass:"secondary--text text-caption"},[_c('u',[_vm._v(_vm._s(_vm.$t(option.title)))])])],1)}),1)],1):_vm._e(),_c('v-spacer'),(_vm.isLogged && _vm.userType != 'default')?_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"content":_vm.notificationsNumber,"value":_vm.notificationsNumber,"bordered":"","bottom":"","color":"red","dot":"","offset-x":"37","offset-y":"14"}}),_c('img',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"width":"38","src":require("@/assets/apotalent/profileIcon.svg")}},'img',attrs,false),on))]}}],null,false,1346277684),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',{staticClass:"rounded-lg"},[_c('v-list-item',{staticClass:"justify-end",on:{"click":function($event){_vm.menu = !_vm.menu}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-close")])],1),_vm._l((_vm.profileOptions),function(option,index){return _c('v-row',{key:index,attrs:{"dense":""}},[(option.permissions.includes(_vm.userType))?_c('v-list-item',{class:option.key == 'help' && 'pa-0',attrs:{"to":option.router != '' ? { name: option.router } : ''},on:{"click":function($event){option.key == 'logout'
                      ? _vm.logOut()
                      : option.key == 'language'
                      ? _vm.toggleLanguage()
                      : option.key == 'help'
                      ? _vm.toggleHelp()
                      : {}}}},[(option.key == 'language')?_c('i18n',{attrs:{"menu":_vm.langaugeMenu},on:{"toggleLanguage":_vm.toggleLanguage}}):_vm._e(),(option.key == 'help')?_c('helpMenu',{attrs:{"menu":_vm.helpMenu},on:{"toggleMenu":_vm.toggleHelp}}):_vm._e(),(!!option.title)?_c('v-list-item-title',{staticClass:"font-weight-light secondary--text"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(option.icon))]),(option.key == 'notifications' && _vm.newNotifications)?_c('v-badge',{attrs:{"bordered":"","bottom":"","color":"red","dot":"","offset-x":"25","offset-y":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$tc(option.title, 2))+" ")],1):_vm._e()],1):_vm._e()],1)})],2)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }