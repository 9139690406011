<template>
  <v-app-bar app flat class="backgroundLayout">
    <v-container>
      <v-row justify-sm="center">
        <v-col cols="12" md="6" lg="6" sm="12" class="ma-0 pa-0">
          <v-toolbar-items>
            <v-icon v-if="back" style="cursor: pointer" @click="$router.go(-1)"
              >mdi-arrow-left</v-icon
            >
            <v-spacer></v-spacer>
            <v-menu
              offset-y
              :nudge-left="70"
              :nudge-bottom="5"
              class="rounded-lg"
              v-if="isLogged"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  alt="ApoTalent Logo"
                  class="shrink ml-4"
                  contain
                  src="@/assets/apotalent/Isotipo white.png"
                  transition="scale-transition"
                  width="40"
                />
              </template>

              <v-list>
                <v-list-item
                  class="legal-options"
                  v-for="(option, index) in legalOptions"
                  :key="index"
                  :to="{ name: option.router }"
                  @click="() => {}"
                >
                  <v-icon small class="mr-2" color="primary">{{
                    option.icon
                  }}</v-icon>
                  <v-list-item-title class="secondary--text text-caption"
                    ><u>{{ $t(option.title) }}</u></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <!-- profile menu -->
            <v-menu
              bottom
              left
              offset-y
              v-model="menu"
              v-if="isLogged && userType != 'default'"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  :content="notificationsNumber"
                  :value="notificationsNumber"
                  bordered
                  bottom
                  color="red"
                  dot
                  offset-x="37"
                  offset-y="14"
                ></v-badge>
                <img
                  width="38"
                  class="mt-2"
                  v-bind="attrs"
                  v-on="on"
                  src="@/assets/apotalent/profileIcon.svg"
                />
              </template>
              <!-- Profile dropdown list -->
              <v-list class="rounded-lg">
                <v-list-item @click="menu = !menu" class="justify-end">
                  <v-icon color="primary">mdi-close</v-icon>
                </v-list-item>
                <v-row
                  dense
                  v-for="(option, index) in profileOptions"
                  :key="index"
                >
                  <v-list-item
                    v-if="option.permissions.includes(userType)"
                    :to="option.router != '' ? { name: option.router } : ''"
                    :class="option.key == 'help' && 'pa-0'"
                    @click="
                      option.key == 'logout'
                        ? logOut()
                        : option.key == 'language'
                        ? toggleLanguage()
                        : option.key == 'help'
                        ? toggleHelp()
                        : {}
                    "
                  >
                    <i18n
                      @toggleLanguage="toggleLanguage"
                      :menu="langaugeMenu"
                      v-if="option.key == 'language'"
                    />
                    <helpMenu
                      @toggleMenu="toggleHelp"
                      :menu="helpMenu"
                      v-if="option.key == 'help'"
                    />
                    <v-list-item-title
                      v-if="!!option.title"
                      class="font-weight-light secondary--text"
                    >
                      <v-icon color="primary">{{ option.icon }}</v-icon>
                      <v-badge
                        v-if="option.key == 'notifications' && newNotifications"
                        bordered
                        bottom
                        color="red"
                        dot
                        offset-x="25"
                        offset-y=""
                      ></v-badge>

                      {{ $tc(option.title, 2) }}
                    </v-list-item-title>
                  </v-list-item>
                  <!-- <v-divider v-show="index == 2" class="mx-4"></v-divider> -->
                </v-row>
              </v-list>
            </v-menu>
          </v-toolbar-items>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>
<script>
//Components
import i18n from "@/components/navbar/i18n.vue";
import helpMenu from "@/components/profiles/HelpMenu.vue";
// Options
import legalOptions from "@/components/layouts/legalOptions.js";
import profileOptions from "@/components/layouts/profileOptions.js";

import { mapGetters, mapActions } from "vuex";
export default {
  components: { i18n, helpMenu },
  props: {
    back: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      profileOptions: profileOptions,
      legalOptions: legalOptions,
      haveNotifications: false,
      items: ["opcion1", "opcion2"],
      langaugeMenu: false,
      menu: false,
      helpMenu: false
    };
  },
  computed: {
    ...mapGetters({ newNotifications: "core/newNotifications" }),
    ...mapGetters({ notifications: "core/notifications" }),
    ...mapGetters({ isLogged: "user/isLogged" }),
    ...mapGetters({ userType: "user/type" }),
    notificationsNumber() {
      return this.newNotifications == true
        ? this.notifications.filter(n => n.unread == true).length
        : 0;
    }
  },
  methods: {
    ...mapActions({ logout: "user/logout" }),
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    toggleLanguage() {
      this.langaugeMenu = !this.langaugeMenu;
    },
    toggleHelp() {
      this.helpMenu = !this.helpMenu;
    },
    async logOut() {
      this.enableLoading();
      try {
        await this.logout();
        this.$router.push({ name: "login" });
      } catch {
        this.$notify({
          title: this.$i18n.tc("notification.log_out_error", 1),
          text: this.$i18n.tc("notification.log_out_error", 2),
          type: "error"
        });
      }
      this.disableLoading();
      this.$notify({
        title: this.$i18n.tc("notification.log_out", 1),
        text: this.$i18n.tc("notification.log_out", 2),
        type: "success"
      });
    }
  }
};
</script>
<style scoped>
.v-divider {
  border-width: 0.5px;
  border-color: #718f94 !important;
}
.backgroundLayout {
  background: linear-gradient(
    180deg,
    #0d7584 0%,
    rgba(255, 255, 255, 0.07) 99.4%
  );
}
.legal-options {
  min-height: 25px;
}
.v-sheet.v-snack__wrapper {
  box-shadow: none !important;
}
</style>
