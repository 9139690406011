export default [
  {
    title: "faqs.title",
    icon: "mdi-help-circle-outline",
    router: "faqs"
  },
  {
    title: "contact.title",
    icon: "mdi-email",
    router: "contact"
  }
];
